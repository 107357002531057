.mint-button {
  display: inline-block;
  font-weight: 700;
  border: 1px solid #0DB68D;
  border-radius: 22px;
  color: #FFFFFF;
  background-color: #0DB68D;
  padding: 10px 34px;
  margin-bottom: 10px;
  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.25));
  font-size: 27px;
  line-height: 30px;
}

.mint-button:hover {
  background-color: #00795C;
  border: 1px solid #0DB68D;
}

.psa-message{
  display: block;
  color: #FFFFFF;
  margin-bottom: 60px;
}