.details-container {
  text-align: left;
  background-color: #FFFFFF;
  color: black;
  border-radius: 20px;
  margin: 0 12px;
}

@media (min-width: 768px) {
  .details-container {
    margin: 0 24px;
  }
}

.atomic-logo {
  border-radius: 30%;
}
