body {
  font-family: 'Inter';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar {
  box-shadow: 0 4px 10px -2px gray;
}

.card-title {
  margin-top: 1.2rem;
  font-size: 1.2rem;
  font-weight: 800;
}

.btn-link {
  text-decoration: none;
  color: gray;
  margin-right: 0.8em;
}

.hero-title {
  letter-spacing: 0.17em;
  font-weight: 400;
  padding-top: 1em !important;
  padding-bottom: 1em !important;
  color: white;
  font-size: 33px;
}

@media (max-width: 768px) {
  .hero-title {
    letter-spacing: 3px;
    font-size: 27px;
  }
}


.ls-17 {
  letter-spacing: 0.17em;
}

.card {
  box-shadow: 0 0 0 3px lightgrey;
  border-radius: 20px;
  border-width: 0px !important;
  box-shadow: 2px 5px 10px #4A4A4A !important;
}

.wv-card-button {
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 30px;
  box-shadow: 2px 5px 10px lightgrey !important;
  background-color: #004179;
}

.wv-card-button-success {
  background-color: #00795C;
}

.cards-row {
  margin: auto;
}

.card-img {
  object-fit: cover;
}

.blue-grad {
  background-image: linear-gradient(#07ABF2, black)
}

.hero-secondary-title {
  padding-top: 1em;
  padding-bottom: 0.5em;
  font-weight: 300;
  font-style: italic;
  color: white;
}


.hero-secondary {
  color: white;
}

.white-link {
  color:white !important;
}

.hero-secondary-sm {
  font-weight: 400;
}

.hero-secondary-body {
  font-weight: 300;
}

.btn-success:hover .btn-success:click .btn-sucess:focus {
  background-color: #00795C !important;
  border-color: #00795C !important;
}

.btn-success {
  background-color: #00795C !important;
  border-color: #00795C !important;
}

.swal-button--install {
  background-color: #0CC879 !important;
  border-color: #0CC879 !important;
}

.nav-logo {
  max-height: 50px;
}