* {
  font-family: Helvetica, sans-serif;
}

.step {
  height: 74px;
  width: 74px;
  border: 4px solid #E5E5E5;
  border-radius: 50%;
  margin-top: -2.275rem;
  z-index: 2;
  padding: 3px;
  color: #4A4A4A;
  font-size: 1.75rem;
  font-weight: 700;
  background-color: #FFFFFF;
}

h3 {
  line-height: 25px;
  font-size: 17px;
}

p {
  font-size: .875rem;
}

.cta-button {
  font-size: 1rem;
  border-radius: 15px;
  border: inherit;
}

.card-title {
  min-height: 81px;
}

.card-footer-text {
  min-height: 60px;
  font-size: 15px;
}

@media (min-width: 992px) {
.trio {
    margin-left: 7% !important;
    margin-right: 7% !important;
  }
}

.counter-container {
  display: inline-block;
  border-radius: 27px;
  color: #FFFFFF;
  background-color: #000000;
  margin-bottom: 35px;
}

.onboarder-coin {
  max-width: 400px;
  display: block;
  -webkit-user-select: none;
  margin: auto;
}

@media (max-width: 992px) {
  .onboarder-coin {
    max-width: 300px;
  }
}

@media (min-width: 768px) {
  .footer-content {
    max-height: 167px;
  }
}

.mobile-alert {
  color: #000000;
  font-weight: 500;
  padding: 10px 24px;
  font-size: 20px;
  border: 3px solid #F00A0A;
  border-radius: 20px;
  margin: 0 12px;
  line-height: 25px;
  font-size: 17px;
  background-color: #F00A0A;
}

.no-more-nfts {
  display: block;
  color: #FFFFFF;
  font-weight: 700;
  font-size: '16px';
}
